//colors
$primary: #fc4303;
$secondary: #9300ca;
$tertiary: #009ad1;
$black: #222222;
$black2: #333333;
$black4: #111e24;
$success: #0bd86c;
$failure: #b81111;
$info: #e5a800;
$disabled: #dedfe5;
$grey-light: #f6f7fa;
$grey: #d8d8d8;
$grey2: #e9e9ed;
$grey3: #c1c0c0;
$grey-dark: #8c9199;
$grey-dark2: #888888;
$grey-blue: #fafbfe; //banner
//$default: #FC4303;
//$alternative: #333333;

// TEMPORARIO
$primary_hover: rgb(226, 57, 0);
$secondary_hover: rgb(127, 0, 173);
$tertiary_hover: rgb(0, 132, 180);
$black_hover: #111111;
//$black2_hover:#222222;
$success_hover: rgb(10, 196, 96);
//$failure_hover: rgb(158, 7, 7);
$info_hover: rgb(224, 172, 1);
