.player__menu {
  position: relative; /* Ensure positioning context for the spinner */
}

.player__menu__content {
  width: 100%;
  height: 100%;
}

.watch-spinner {
  border: 4px solid rgba(252, 67, 3, 0.3); /* Light orange */
  border-top: 4px solid #fc4303; /* Bright orange */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: watch-spin 1s linear infinite;
  position: absolute;
  z-index: 2;
  top: 50%; /* Position at a fixed distance from the top */
  left: 50%;
  transform: translateX(-50%);
}

.watch-menu-blur {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
  opacity: 0.7;
  transition: all 0.3s ease;
}

@keyframes watch-spin {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}
