.cart-spinner {
  border: 8px solid rgba(255, 165, 0, 0.3);
  /* Light orange */
  border-top: 8px solid #fc4303;
  /* Bright orange */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cartBlur {
  filter: blur(5px);
  pointer-events: none;
}