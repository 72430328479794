.test {
  &__watch {
    background-color: $grey-blue;
    padding: 5px 0;
    color: $black;
    height: inherit;
    align-self: center;
    overflow: auto;

    h2 {
      font-size: 1.75rem;
    }
    .container {
      margin-top: auto;
      margin-bottom: auto;
    }

    @media (max-width: 992px) {
      background-color: rgba(255, 255, 255, 0.9);
      padding: 20px 20px 0;
      height: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1001;

      .container {
        margin: auto;
        background-color: $grey-light;
        padding: 15px;
        overflow: auto;

        .btn-close {
          left: 0;
        }
      }
    }
  }
  
  &__content {
    margin: 0 auto;
    text-align: center;
    position: relative;
    transition: filter 0.3s ease;

    h3 {
      color: $primary;
    }

    p {
      font-size: 1rem;
    }

    &.loading {
      filter: blur(4px);
    }

    .has-error {
      // General input styles
      border-color: $red;
      background-color: lighten($red, 40%);
      box-shadow: 0 0 5px rgba($red, 0.5);
      color: darken($red, 20%);
    
      &:focus {
        border-color: darken($red, 10%);
        box-shadow: 0 0 8px rgba($red, 0.7);
      }
    
      &.form-control {
        border-width: 2px;
      }
    
      // Styling for checkboxes and radios
      input[type="checkbox"], input[type="radio"] {
        // Remove default border and background styles
        border: 1px solid $red;
        background-color: transparent;
    
        &:checked {
          background-color: lighten($red, 30%);
        }
    
        &:focus {
          outline: none;
          box-shadow: 0 0 5px rgba($red, 0.5);
        }
      }
    
      // Styling specifically for text inputs
      input[type="text"], textarea {
        border-color: $red;
        background-color: lighten($red, 40%);
        box-shadow: 0 0 5px rgba($red, 0.5);
    
        &:focus {
          border-color: darken($red, 10%);
          box-shadow: 0 0 8px rgba($red, 0.7);
        }
      }
    
      // Label styling for better visibility
      label {
        color: $red;
      }
    }

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      font-size: 2rem;
      color: $primary;
      display: none;
    }

    &.loading .spinner {
      display: block;
    }
  }

  &__feedback {
    .icon {
      font-size: 80px;
      width: 100px;
      height: 100px;
    }
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
