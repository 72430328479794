html {
  scroll-behavior: smooth;

  .relatorio {
    flex-wrap: wrap;
  }

  .img {
    position: relative;
    width: 100%;
    overflow: hidden;

    img,
    iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      height: auto;
      width: 100%;
      transform: translate(-50%, -50%);
    }

    iframe {
      height: 100%;
      width: 100%;
    }

    &.aspectRatio--397x223 {
      padding-top: calc((223 / 397) * 100%);
    }
  }
  .sections-companies {
    overflow: hidden;
  }
  .sections-companies,
  .targe {
    // alteração da largura do container
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      @media (min-width: 1200px) {
        max-width: 980px;
      }
    }

    .container {
      &-2 {
        @media (min-width: 1200px) {
          max-width: 1200px !important;
        }
      }
    }

    overflow-x: hidden;
    padding: 55px 0 50px 0;
    color: $black3;

    p {
      font-family: $title-font;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    h2,
    h3 {
      font-family: $paragraph-font;
    }

    h1 {
      font-size: 32px;
      line-height: 41px;
      padding-top: 2em;

      @media (max-width: 799px) {
        padding-top: 10px;
        text-align: center;
      }
    }

    h2 {
      font-size: 30px;
      line-height: 40px;

      @media (max-width: 799px) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    h3 {
      font-size: 20px;
      line-height: 30px;

      @media (max-width: 799px) {
        text-align: center;
      }
    }

    .buttons {
      @media (max-width: 991px) {
        display: flex;
        justify-content: center;
        margin: 30px 0;
      }
    }

    .no-wrap {
      white-space: nowrap;
    }

    .purple-rectangle {
      overflow: visible;
      z-index: 0;
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        transform: rotate(-4.85deg);
        background-color: $secondary;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
      }
    }

    .centralize-image {
      display: flex;
      justify-content: center;
    }

    .plan-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .cardTestimonial {
      border: 3px solid #9300ca;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 30px 35px;
      height: 100%;
      p {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        font-family: $paragraph-font !important;
      }

      .userInfo {
        &-about {
          margin: 15px 0 25px 0;
          p {
            margin: 0;
            &:first-of-type {
              text-transform: uppercase;
            }
          }
        }
        &-image {
          max-width: 35%;
          border: 3px solid $secondary;
          border-radius: 100px;
          height: 100%;
          width: 100%;
        }
      }
    }

    .cardType {
      display: flex;
      flex-wrap: wrap;
      background-color: $grey-light;
      border-radius: 10px;
      justify-content: center;
      padding: 30px 35px;
      height: 100%;
      color: $black3;
      & h1 {
        font-family: $paragraph-font;
        width: 100%;
        line-height: 17px;
        text-align: left;
        font-size: 16px;
        @media (max-width: 799px) {
          text-align: center;
        }
      }
      &-button {
        width: 100%;
        margin: 0;
        display: flex;
        align-items: flex-end;

        a {
          width: 100%;

          button {
            width: 100% !important;
          }
        }
      }

      @media (max-width: 799px) {
        &-plan {
          text-align: center;
        }
        text-align: center;
      }
    }

    .purple-targe {
      background-color: $secondary;
      color: $grey-light !important;
      font-family: $paragraph-font;
      padding: 30px 0;
      margin: 0 !important;

      p {
        margin: 0;
        line-height: 100%;
      }

      .container {
        display: flex;
        align-items: center;

        @media (max-width: 799px) {
          display: block;
        }
      }
    }

    .carousel {
      &-indicators li {
        background-color: $secondary;
      }

      &-logos {
        .row {
          justify-content: center;
          align-items: center;
        }
      }

      &-inner {
        @media (max-width: 799px) {
          margin-bottom: 15px;
        }

        @media (max-width: 991px) {
          margin-bottom: 20px;
        }
      }
    }

    .no-mobile {
      @media (max-width: 991px) {
        display: none;
      }
    }

    .mobile-only {
      display: none;

      @media (max-width: 991px) {
        display: block;
      }
    }

    @media (max-width: 799px) {
      padding: 0;
      padding-top: 50px;
    }
  }

  .targe {
    padding: 0;

    &-button {
      button {
        @media (max-width: 799px) {
          margin-top: 30px;
          width: 100%;
        }
      }
    }
  }

  #home {
    height: 70vh;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
      height: auto;
    }
  }

  #home,
  #plans,
  #customers {
    background-color: $black4;
    color: $grey-light;
  }

  #catalog,
  #credits,
  #testimonial {
    background-color: $grey-light;
  }

  #lms,
  #customized,
  #form {
    background-color: $white;
  }

  footer {
    margin-top: 0 !important;
  }
}
