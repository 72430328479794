.cookie-bar {
  color: white;
  height: fit-content;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 9999999999;
  display: block !important;
  font-size: 14px;
  font-weight: 400;

  &-row {
    align-items: center;
  }

  &-text {
    @media (max-width: 700px) {
      padding-bottom: 10px;
    }
  }

  @media (max-width: 700px) {
    padding-bottom: 0;
  }

  a {
    color: #fff;
    font-weight: 500;
    text-decoration: underline;
  }
}

.cookie-bar-button {
  width: 100%;
  margin-top: 5px;
  background-color: #222;
  border-color: #222;

  &:hover {
    background-color: #fc5921;
    border-color: inherit;
  }

  @media (max-width: 991) {
    margin: 30px 0;
  }
}
